import { useEffect, useState } from 'react';

import {
  AudioTrack,
  LiveKitRoom,
  useTracks,
  VideoTrack,
} from '@livekit/components-react';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { Track } from 'livekit-client';
import { v4 as uuid } from 'uuid';

import './Live.css';
import { getToken, SERVER_URL } from './requests';

const id = uuid();

const Live = () => {
  const [token, setToken] = useState('');

  useEffect(() => {
    getToken(id)
      .then((res) => {
        setToken(res.data?.data?.rtcToken ?? '');
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [id]);

  return (
    <div className="live">
      <div className="live__container">
        <LiveKitRoom token={token} serverUrl={SERVER_URL} connect={true}>
          <SingleTrack />
        </LiveKitRoom>
      </div>
    </div>
  );
};

const SingleTrack = () => {
  const trackRefs = useTracks([Track.Source.Camera]);
  const audioRefs = useTracks([Track.Source.Microphone, Track.Kind.Audio]);

  const handleFullScreen = () => {
    const video = document.querySelector('.lk-participant-media-video');
    video.requestFullscreen();
  };

  return (
    <div className="stream">
      {trackRefs?.[0] && audioRefs?.[0] ? (
        <>
          <VideoTrack trackRef={trackRefs[0]} />
          <AudioTrack trackRef={audioRefs[0]} autoPlay />
          <button className="lk-fullscreen" onClick={handleFullScreen}>
            <FullscreenIcon />
          </button>
        </>
      ) : null}
    </div>
  );
};

export default Live;
