import { Line } from 'components/icons';
import RenderIf from 'components/RenderIf';
import { useAuth } from 'hooks';
import { actions } from 'modules/home/_redux/gameRedux';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import './GameList.css';

const GameList = ({ className = '', isDesktop }) => {
  const { gameId } = useSelector((state) => state.game);
  const { winningAmount } = useSelector((state) => state.auth);
  const isAuth = useAuth();
  const [games] = useState(
    isDesktop
      ? [
          {
            id: 1,
            image: './media/images/classic.png',
            name: 'Classic',
            url: 'classic',
            type: 'link',
            showLine: true,
          },
          {
            id: 2,
            image: './media/images/nard-36.png',
            name: 'Nard 36',
            url: 'nard-36',
            type: 'link',
            showLine: true,
          },
          {
            id: 3,
            image: './media/images/sapper.png',
            name: 'Sapper',
            url: 'sapper',
            type: 'link',
            showLine: true,
          },
        ]
      : [
          {
            id: 1,
            image: './media/images/classic.png',
            name: 'Classic',
            url: 'classic',
            type: 'link',
            showLine: true,
          },
          {
            id: 2,
            image: './media/images/nard-36.png',
            name: 'Nard 36',
            url: 'nard-36',
            type: 'link',
            showLine: false,
          },
          {
            id: 3,
            image: './media/images/sapper.png',
            name: 'Sapper',
            url: 'sapper',
            type: 'link',
            showLine: true,
          },
          {
            id: 4,
            type: 'info',
            showLine: true,
          },
        ]
  );

  const location = useLocation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const params = new URLSearchParams(location.search);

  const onClickGame = (id) => () => {
    const game = games.find((i) => i.id === id);
    navigate(`/?game=${game?.url}`);
  };

  useEffect(() => {
    if (location.pathname === '/' && !location.search) {
      navigate(`/?game=classic`);
    }
  }, [location]);

  useEffect(() => {
    const gameUrl = params.get('game');
    if (gameUrl) {
      const game = games.find((i) => i.url === gameUrl);
      dispatch(actions.setGameId(game?.id));
    }
  }, [location]);

  return (
    <div
      className={`game-list ${className} ${isAuth ? 'game-list__auth' : ''}`}
    >
      {games.map((game) =>
        game.type === 'info' && isAuth ? (
          <h6
            key={game.id}
            className="text-white game-list__text d-flex align-items-center"
          >
            Uduş məbləği: {winningAmount} AZN
          </h6>
        ) : game.type === 'link' ? (
          <div
            key={game.id}
            onClick={onClickGame(game.id)}
            className="d-flex align-items-center gap-1"
            role="button"
          >
            <img width="42" height="42" src={game.image} />
            <span
              className={`game-list__item ${
                game.url === params.get('game') && location.pathname === '/'
                  ? 'game-list__item--active'
                  : ''
              }`}
            >
              {game.name}
            </span>
            <RenderIf condition={game.showLine}>
              <Line />
            </RenderIf>
          </div>
        ) : null
      )}
    </div>
  );
};

export default GameList;
